.wrapper {
  background-color: transparent !important;
  height: auto !important;
}

.form-container {
  width: 100%;
  max-width: 500px;
  padding: 50px 20px 20px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 10px #0000001a;
  background-color: #fff;
  text-align: center;
}


.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 16px;
  background-color: #00000009;
}

.submit-button {
  margin-left: 10px;
  padding: 8px 30px;
  border: none;
  border-radius: 30px;
  background-color: #6e53c6;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.submit-button:hover {
  background-color: #5e33ee;
}

.btn-container {
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.full-glow-background-bottom {
  position: relative;
  height: auto;
  /* Full view height */
  width: 100%;
  background: radial-gradient(circle at bottom left,
      rgba(230, 247, 255, 0.8) 5%,
      /* Light blue for the rest */
      rgba(255, 255, 255, 0.5) 30%
      /* White transition */
    );
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.glow-content {
  position: relative;
  z-index: 1;
  /* Ensure content is above the glow background */
}

.full-glow-background-bottom::before {
  content: '';
  position: absolute;
  bottom: -50px;
  left: -50px;
  width: 20px;
  height: 20px;
  background: rgba(196, 196, 196, 0.3);
  /* Soft gray color */
  filter: blur(100px);
  /* Creates the glow effect */
  z-index: 0;
  /* Behind the content */
}


.full-glow-background-top {
  position: relative;
  height: auto;
  /* Full view height */
  width: 100%;
  background: radial-gradient(circle at top left,
      rgba(230, 247, 255, 0.8) 5%,
      /* Light blue for the rest */
      rgba(255, 255, 255, 0.5) 30%
      /* White transition */
    );
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-glow-background-top::before {
  content: '';
  position: absolute;
  bottom: -50px;
  left: -50px;
  width: 20px;
  height: 20px;
  background: rgba(196, 196, 196, 0.3);
  /* Soft gray color */
  filter: blur(100px);
  /* Creates the glow effect */
  z-index: 0;
  /* Behind the content */
}

* {
  scroll-behavior: smooth;
}
.thumbnail {
  position: relative;
  display: inline-block;
}

.play-button {
  background-color: white;
  padding: 15px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.custom-toast {
  font-size: 18px; 
}

::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
}
